import BaseLayout from '../layouts/BaseLayout';
import { Navigate, RouteObject } from 'react-router';
import { lazy, Suspense } from 'react';
import SuspenseLoader from '../components/SuspenseLoader';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/pages/UserManagement/Login')));
const ForgotPassword = Loader(
  lazy(() => import('src/pages/UserManagement/Forgotpassword'))
);
const ResetPassword = Loader(
  lazy(() => import('src/pages/UserManagement/Resetpassword/index'))
);

const baseSiteRoute: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: '*',
        element: <Navigate to="/" replace />
      }
    ]
  }
];
export default baseSiteRoute;
