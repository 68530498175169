// import Card from '@mui/material/Button';
import loader from './loader.gif';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';

const CardDataLoader = () => {
  return (
    <Card>
      <Box
        alignContent={'center'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '50vh',
          alignItems: 'center'
        }}
      >
        <img
          alt="loader"
          src={loader}
          className="loader"
          width={50}
          height={50}
        />
      </Box>
    </Card>
  );
};
export default CardDataLoader;
