import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import Techtaliyalogo from './techtaliya-logo.png';

const LogoWrapper = styled(Box)(() => ({
  height: '40px',
  marginBottom: '10px'
}));

function Logo() {
  return (
    <Link to="/">
      <LogoWrapper>
        <img src={Techtaliyalogo} alt={Techtaliyalogo} width="100" />
      </LogoWrapper>
    </Link>
  );
}

export default Logo;
