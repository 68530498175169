import { Box, styled } from '@mui/material';
import bgLogin from 'src/assets/img/bg-login.png';

interface HomePageWrapperProps {
  children?: React.ReactNode;
}

const HomePageWrapperBox = styled(Box)(() => ({
  backgroundImage: `url(${bgLogin})`,
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  overflow: 'hidden',
  backgroundPosition: 'center left',
  flex: 1
}));

const HomePageWrapper: React.FC<HomePageWrapperProps> = ({
  children,
  ...rest
}) => {
  return <HomePageWrapperBox {...rest}>{children}</HomePageWrapperBox>;
};
export default HomePageWrapper;
