import { Box } from '@mui/material';
import SwitchButton from './Switch';
import { UserTypes } from 'src/common/enums/enums';
// import HeaderSearch from './Search';
// import HeaderNotifications from './Notifications';

function HeaderButtons() {
  return (
    <Box>
      {JSON.parse(localStorage.getItem('userData')).type ===
        UserTypes.Administrator && <SwitchButton />}
    </Box>
  );
}

export default HeaderButtons;
