import React from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
type ThemeProps = {
  children?: React.ReactNode;
};

const ThemeProviderWrapper: React.FC<ThemeProps> = (props) => {
  const currentTheme = 'PureLightTheme';
  const theme = themeCreator(currentTheme);
  return <ThemeProvider theme={theme}> {props.children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
