import { Outlet } from 'react-router-dom';
import HomePageWrapper from 'src/components/HomePageWrapper';
interface BaseLayoutProps {
  children?: React.ReactNode;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  return <HomePageWrapper>{children || <Outlet />}</HomePageWrapper>;
};

export default BaseLayout;
