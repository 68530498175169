import { useContext, FC } from 'react';
import { useNavigate } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { styled } from '@mui/material/styles';

import { Badge, Box, Drawer, Hidden, IconButton } from '@mui/material';
import Logo from 'src/components/Logo';
import SidebarMenu from './SidebarMenu';
import TextsmsIcon from '@mui/icons-material/Textsms';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import request from 'src/utils/request';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        padding-bottom: 60px;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 70px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.colors.primary.main} solid 1px;
        border-bottom-width: 3px;
`
);

const SidebarFooter = styled(Box)(({}) => ({
  height: '60px',
  backgroundColor: 'rgb(17, 137, 230, 0.9)',
  alignItems: 'center'
}));

const Sidebar: FC<any> = () => {
  const navigate = useNavigate();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const handleLogout = async () => {
    await request.post('auth/logout');
    localStorage.clear();
    navigate('/');
  };

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Box>
                <Logo />
              </Box>
            </TopSection>
            <SidebarMenu />
          </Scrollbars>
          <SidebarFooter pt={1} sx={{ textAlign: 'center' }}>
            <Badge
              badgeContent={4}
              color="error"
              variant="dot"
              sx={{
                backgroundColor: 'rgba(17, 25, 42, 0.2)',
                borderRadius: '8px'
              }}
            >
              <TextsmsIcon
                sx={{
                  padding: '8px',
                  color: 'rgb(255, 255, 255, 0.7)',
                  fontSize: 40
                }}
              />
            </Badge>
            <IconButton
              aria-label="Logout"
              sx={{
                ml: 2,
                backgroundColor: 'rgba(17, 25, 42, 0.2)',
                color: 'rgb(255, 255, 255, 0.7)'
              }}
              onClick={handleLogout}
            >
              <PowerSettingsNewIcon />
            </IconButton>
          </SidebarFooter>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <Logo />
              </TopSection>
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
