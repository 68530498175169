import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';
import { IconButton, Tooltip } from '@mui/material';
import request from 'src/utils/request';

function SwitchButton() {
  const handleClickOpen = async () => {
    try {
      const response = await request.get(`auth/sso-token`);
      const ssoToken = response?.data;
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_USER_URL}?token=${ssoToken}`;
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Tooltip arrow title="Switch to User">
        <IconButton color="primary" onClick={handleClickOpen}>
          <ChangeCircleRoundedIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default SwitchButton;
