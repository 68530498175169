import axios, { AxiosError } from 'axios';
import { extractErrorCodeFromError, formatAxiosError } from './error-utils';
import { ErrorCodes } from 'src/common/enums/enums';

// create an axios instance
const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL // url = base url + request url
});

// request interceptor do something before request is sent
service.interceptors.request.use(
  (config: any) => {
    // Set Authorization Header only if not already set by the request
    if (!config.headers.hasOwnProperty('Authorization')) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        config.headers.Authorization = `Bearer ${userData.accessToken}`;
      }
    }
    if (config.data instanceof FormData) {
      config.headers['content-type'] = 'multipart/form-data';
    } else {
      config.headers['content-type'] = 'application/json';
    }
    config.headers['Accept'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// interceptor response
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    const errorCode = extractErrorCodeFromError(error);
    if (errorCode === ErrorCodes.UnAuthorized) {
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_ADMIN_UI_URL}`;
    } else {
      const formattedError = formatAxiosError(error?.response?.data);
      return Promise.reject(formattedError);
    }
  }
);

export default service;
